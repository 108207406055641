const theMovieDbList = async (listId, page = 1) => {
    const url = `https://api.themoviedb.org/4/list/${listId}?language=en-US&page=${page}`;
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_THE_MOVIE_DB_TOKEN}`
        }
    };

    const response = await fetch(url, options);
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch movies');
    }
    
    return data;
};

export default theMovieDbList;