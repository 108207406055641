const Hero = ({ title, subtitle, image }) => {
    return (
        <div 
            className="hero" 
            style={{ 
                backgroundImage: `url(${image})`,
                aspectRatio: '16/9',
                minHeight: '250px'
            }}
        >
            <img 
                src={image} 
                alt="hero background" 
                loading="eager" 
                fetchPriority="high"
                style={{ display: 'none' }}
            />
            <div className="hero-content">
                <h1>{title}</h1>
                <h4>{subtitle}</h4>
            </div>
        </div>
    );
}

export default Hero;