const Card = ({ card }) => {
    const { color, tag, image, alt, title } = card;
    
    return (
        <div className="card">
            <div className="card-tag" style={{ backgroundColor: color }}>
                {tag}
            </div>
            <div 
                className="card-image"
                style={{ aspectRatio: '3/2' }}
            >
                <img 
                    src={image} 
                    alt={alt}
                    loading="lazy"
                    width="300"
                    height="200"
                />
            </div>
            {title && <div className="card-content">
                <h3>{title}</h3>
            </div>}
        </div>
    );
}

export default Card;