const getOptimizedImageUrl = (imageUrl, options = {}) => {
  if (!imageUrl || !imageUrl.includes('images.ctfassets.net')) return imageUrl;

  const {
    width,
    height,
    quality = 75,
    format = 'webp',
    fit = 'fill',
    focus = 'center',
    radius = 0,
    backgroundColor
  } = options;

  const url = new URL(imageUrl);
  const params = new URLSearchParams();

  if (width) params.append('w', width);
  if (height) params.append('h', height);
  params.append('q', quality);
  params.append('fm', format);
  params.append('fit', fit);
  params.append('f', focus);
  if (radius > 0) params.append('r', radius);
  if (backgroundColor) params.append('bg', backgroundColor);

  return `${url.href}?${params.toString()}`;
};

export default getOptimizedImageUrl; 