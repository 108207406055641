import { useState, useEffect } from 'react';
import theMovieDbList from '../services/TheMovieDB';
import RandomMovie from '../components/RandomMovie';

const FavoriteMovies = () => {
    const [movies, setMovies] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchAllMovies = async () => {
            try {
                setLoading(true);
                let currentPage = 1;
                let allMovies = [];
                let hasMorePages = true;

                while (hasMorePages) {
                    const response = await theMovieDbList('8498660', currentPage);
                    allMovies = [...allMovies, ...response.results];
                    
                    // Check if there are more pages
                    hasMorePages = currentPage < response.total_pages;
                    currentPage++;
                }

                setMovies(allMovies);
            } catch (error) {
                console.error('Error fetching movies:', error);
                setError('Failed to load movies');
            } finally {
                setLoading(false);
            }
        };

        fetchAllMovies();
    }, []);

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (loading && movies.length === 0) {
        return <div className="loading-message">Loading movies...</div>;
    }

    return (
        <div className="favorite-movies">
            {movies.length > 0 && (
                <div className="hero" style={{ 
                    backgroundImage: `url(https://image.tmdb.org/t/p/original${movies[0].backdrop_path})` 
                }}>
                    <div className="hero-content">
                        <h2>Can't decide what to watch?<br /> Here are some of my favorite movies.</h2>
                        <RandomMovie movies={movies} />
                    </div>
                </div>
            )}
            <div className="movies-grid">
                {movies.map(movie => (
                    <div key={movie.id} className="movie-item">
                        <img 
                            src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} 
                            alt={movie.title}
                            className="movie-poster"
                        />
                        <div className="movie-details">
                            <h3 className="movie-title">
                                {movie.title}
                                <span className="movie-year">
                                    ({new Date(movie.release_date).getFullYear()})
                                </span>
                            </h3>
                            <p className="movie-description">{movie.overview}</p>
                            <div className="movie-meta">
                                <span className="movie-rating">
                                    ★ {movie.vote_average.toFixed(1)}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FavoriteMovies;